import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { List, ListItem, ListItemText } from '@mui/material';

function RoomSelection() {
  const [rooms, setRooms] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRooms = async () => {
      try {
        const response = await axios.get('/api/rooms');
        console.log(response.data);
        setRooms(response.data);
      } catch (error) {
        console.error('Failed to fetch rooms:', error.message, error.stack);
      }
    };

    fetchRooms();
  }, []);

  const handleRoomSelect = (roomId) => {
    navigate(`/${roomId}`);
  };

  return (
    <List>
      {rooms.map((room) => (
        <ListItem button key={room.id} onClick={() => handleRoomSelect(room.id)}>
          <ListItemText primary={room.displayName} />
        </ListItem>
      ))}
    </List>
  );
}

export default RoomSelection;