import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import RoomSelection from '../components/RoomSelection.jsx';
import RoomStatusDisplay from '../components/RoomStatusDisplay.jsx';
import MeetingAgendaDisplay from '../components/MeetingAgendaDisplay.jsx';
import { Grid, Container } from '@mui/material';

function Home() {
  const { roomId } = useParams();
  const [meetings, setMeetings] = useState([]);
  const [roomStatus, setRoomStatus] = useState({ status: 'loading' });

  const fetchMeetingAgenda = async () => {
    try {
      const response = await axios.get(`/api/meeting-agenda/${roomId}`);
      setRoomStatus(response.data.roomStatus);
      setMeetings(response.data.events);
    } catch (error) {
      console.error('Failed to fetch meeting agenda:', error.message, error.stack);
      // Set roomStatus to a default value that includes a 'status' property to avoid undefined access
      setRoomStatus({ status: 'error' });
      setMeetings([]);
    }
  }

  useEffect(() => {
    if (roomId) {
      // Fetch meeting agenda every 60 seconds
      fetchMeetingAgenda();
      const intervalId = setInterval(fetchMeetingAgenda, 60000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [roomId]); 


  const handleRoomChange = () => {
    if (roomId) {
      fetchMeetingAgenda();
    }
  }


  if (!roomId) {
    return <RoomSelection />;
  } else {
    return (
      <Container>
        <Grid container spacing={2} 
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{minHeight: {md: '100vh', sm:'auto'}}}>
          <Grid item xs={12} md={6} >
            <RoomStatusDisplay 
                status={roomStatus.status} roomId={roomId} 
                roomName={roomStatus.roomName} 
                currentEvent={roomStatus.currentEvent} 
                onchange={handleRoomChange}
                />
          </Grid>
          <Grid item xs={12} md={6}>
            <MeetingAgendaDisplay meetings={meetings} />
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default Home;