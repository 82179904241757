import React, { useState }  from 'react';
import { Paper, Typography, Chip, Card, CardHeader, Avatar, CardMedia, Container, Button } from '@mui/material';
import { stringAvatar } from '../lib/utils';
import ReusableDialog from './ReusableDialog';

function RoomStatusDisplay({ roomId, roomName, status, currentEvent, onchange }) {
  const [open, setOpen] = useState(false);

  function dateToMelbourneTime(dateTime) {
    const date = new Date(`${dateTime}Z`);
    return date.toLocaleTimeString('en-AU', { timeZone: 'Australia/Melbourne' });
  }

  const handleClick = () => {
    if (status === 'vacant') {
      console.info('You clicked the Chip to book the room.');
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  }

  const handleCreateMeeting = async (duration) => {
    setOpen(false);
    console.info('Creating a meeting for ' + duration + ' minutes');
    const response = await fetch('/api/meetings', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ roomId, duration }),
    });
    if (response.ok) {
      console.log('Meeting created successfully');
      onchange();
    } else {
      console.error('Failed to create meeting');
    }
  }

  return (
    <Paper sx={{minHeight: {md: '90vh', sm:'auto'} , p:2, backgroundColor: 'rgba(246, 247, 248, 0.5)'}} square={false}>
        <ReusableDialog 
          open={open} handleClose={() => handleClose()} title="Meeting Duration" 
          actions={[{ handler: () => {handleClose()}, color: 'secondary', label: 'Close' }
          ]} >
              <Container sx={{width:{md: '400px', sm:'auto'}}}>
                <Button onClick={() => handleCreateMeeting('15')}>15 mins</Button>
                <Button onClick={() => handleCreateMeeting('30')}>30 mins</Button>
                <Button onClick={() => handleCreateMeeting('45')}>45 mins</Button>
                <Button onClick={() => handleCreateMeeting('60')}>1 hour</Button>
              </Container>
          </ReusableDialog>
      <Typography variant="h4">{roomName}</Typography>
      <Chip label={status === 'occupied' ? status.toUpperCase() : 'Click to book room'} 
            color={status === 'occupied' ? 'error' : (status === 'vacant' ? 'success' : 'primary')} 
        sx={{p:5, mt:2, width: '100%'}} onClick={handleClick} />
        
      <Card sx={{ p:2,mt:2 }}>
        <CardHeader
        avatar={
          <Avatar aria-label="initials"  {...stringAvatar(currentEvent ? currentEvent.subject : 'A V' )} />
        }
        title={currentEvent ? (status === 'occupied' ? 'Current Event: ' + currentEvent.subject : ' '):'Available to use'}
        subheader={currentEvent ? `${dateToMelbourneTime(currentEvent.start.dateTime)} - ${dateToMelbourneTime(currentEvent.end.dateTime)}` : ''}
      />
      <CardMedia
        component="img"
        height="200"
        image={status === 'occupied' ? "/inuse_meeting_room_with_oval_desk.png" : "/empty_meeting_room_with_oval_desk.png"}
        alt="Meeting room"
      />  
      </Card>

    </Paper>
  );
}

export default RoomStatusDisplay;