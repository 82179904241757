import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

const ReusableDialog = ({ open, handleClose, title, children, actions }) => {
    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="dialog-title">
            <DialogTitle id="dialog-title">{title}</DialogTitle>
            <DialogContent dividers>
                {children}
            </DialogContent>
            <DialogActions>
                {actions.map((action, index) => (
                    <Button key={index} onClick={action.handler} color={action.color}>
                        {action.label}
                    </Button>
                ))}
            </DialogActions>
        </Dialog>
    );
};

ReusableDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    actions: PropTypes.arrayOf(
        PropTypes.shape({
            handler: PropTypes.func.isRequired,
            color: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        })
    ).isRequired,
};

export default ReusableDialog;