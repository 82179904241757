import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Typography, styled, Avatar } from '@mui/material';
import {Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineOppositeContent, TimelineDot} from '@mui/lab';
import { red } from '@mui/material/colors';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const CustomTimelineItem = styled(TimelineItem)(({ theme }) => ({
  // Your custom styles here
  minHeight: 40,
  '&:before': {
    // Override the pseudo-elements or any specific part
    display: 'none',
  },
}));

const CustomTimelineDot = styled(TimelineDot)(({ theme }) => ({
  // Your custom styles here
  margin: 0,
  '&:before': {
    // Override the pseudo-elements or any specific part
    display: 'none',
  },
}));

function MeetingAgendaDisplay({ meetings }) {

  const timeArray = [];
  const startTime = new Date();
  const nearest15Mins = Math.floor(startTime.getMinutes() / 15) * 15;
  startTime.setMinutes(nearest15Mins);
  startTime.setSeconds(0);

  if (meetings.length > 0) {
    const firstMeetingStartTime = new Date(meetings[0].start.dateTime+"Z");
    if (firstMeetingStartTime < startTime) {
      startTime.setHours(firstMeetingStartTime.getHours(), 0, 0, 0);
    }
  }

  for (let i = 0; i < 32; i++) {
    const time = new Date(startTime.getTime() + i * 15 * 60 * 1000); // Add 15 minutes to the start time
    timeArray.push(time);
  }

  function dateToMelbourneDate(dateTime) {
    const date = new Date(`${dateTime}Z`);
    return date.toLocaleDateString('en-AU', { timeZone: 'Australia/Melbourne' });
  }


  function getEvent(time) {
    return meetings.find((meeting) => {
      const meetingStartTime = new Date(meeting.start.dateTime+"Z");
      const meetingEndTime = new Date(meeting.end.dateTime+"Z");
      return time >= meetingStartTime && time < meetingEndTime;
    });
  }

  return (
      <Paper sx={{minHeight: {md: '90vh', sm:'auto'}, p:2, backgroundColor: 'rgba(246, 247, 248, 0.5)'}} square={false} >
          <Typography variant="h6">Upcoming Meetings</Typography>
          <Timeline sx={{
            width: '100%',
            position: 'relative',
            overflow: 'auto',
            maxHeight: '80vh',
            '& ul': { padding: 0 },
          }}
            >
          {timeArray.map((time, index) => (
            <CustomTimelineItem key={index}>
              <TimelineOppositeContent
                sx={{ m: 'auto 0' }}
                align="right"
                variant="body2"
                color="text.secondary"
              >
                {time.toLocaleTimeString('en-AU', { hour: 'numeric', minute: 'numeric' })}
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector />
                {getEvent(time) ? 
                    <Avatar sx={{ bgcolor: red[500], width: 28, height: 28 }} ><AccountCircleIcon/></Avatar>
                   : 
                  <CustomTimelineDot variant="outlined" color="success" ></CustomTimelineDot>}
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ m: 'auto 0' }}
                align="left"
                variant="body"
                color="text.primary"
              >
                  {getEvent(time) ? getEvent(time).subject : ''}
              </TimelineContent>
            </CustomTimelineItem>
          ))}
          </Timeline>

        </Paper>
  );
}

MeetingAgendaDisplay.propTypes = {
  meetings: PropTypes.arrayOf(PropTypes.shape({ 
    subject: PropTypes.string,
    start: PropTypes.shape({ dateTime: PropTypes.string }),
    end: PropTypes.shape({ dateTime: PropTypes.string }),
  })),
};

export default MeetingAgendaDisplay;